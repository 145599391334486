import { detect } from "detect-browser";

const browser = detect();
const isWebp =
  (browser.name === 'chrome' && browser.versionNumber > 32)
  || (browser.name === 'firefox' && browser.versionNumber > 65)
  || (browser.name === 'opera' && browser.versionNumber > 19)
  || (browser.name === 'edge' && browser.versionNumber > 18)
  || (browser.name === 'safari' && browser.versionNumber > 16)
  || (document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0);

export default isWebp;